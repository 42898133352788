body {
  font-family: "Poppins", sans-serif !important;
  color: #fff;
  font-size: 16px;
}

a {
  color: #fff;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: "Coiny", cursive !important;
}

a {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.App {
  overflow: hidden;
}

.title {
  font-size: 70px;
  color: white;
  -webkit-text-fill-color: #643842; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
  text-align: center;
  @media screen and (max-width: 1400px) {
    font-size: 60px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 50px;
  }
  @media screen and (max-width: 768px) {
    font-size: 40px;
    -webkit-text-stroke-width: 2px;
  }
}

.btn-primary {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: #faab48;
  box-shadow: 4px 4px 0px 0px #643842;
  border-radius: 12px;
  transition: all 0.3s ease-in;
  &:hover {
    background-color: #643842;
    box-shadow: 4px 4px 0px 0px #faab48;
  }
}

.btn-secondary {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: #643842;
  box-shadow: 2px 2px 0px 0px #3e1f26;
  transition: all 0.3s ease-in;
  &:hover {
    background-color: #faab48;
  }
}

.container {
  max-width: 1500px;
  padding: 0 20px;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    padding: 0 16px;
  }
}

.header {
  background-color: #fdeedb;
  &-top,
  &-bottom {
    display: flex;
  }
  &-main {
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
    &-right {
      position: relative;
      max-width: 860px;
      &-text {
        max-width: 100%;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &-btn {
    max-width: 357px;
    margin: 60px auto 0;
    @media screen and (max-width: 600px) {
      margin: 24px auto 0;
    }
    &-socials {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      gap: 20px;
      @media screen and (max-width: 600px) {
        font-size: 16px;
        gap: 10px;
      }
    }
  }
}

.pb {
  display: block;
  padding-bottom: 50px !important;
}

.intro {
  position: relative;
  background-color: #50221b;
  background-image: url("./assets/img/trade.svg");
  background-size: contain;
  background-position: center;
  object-fit: cover;
  &-info {
    padding: 50px;
    max-width: 1280px;
    margin: 0 auto;
    @media screen and (max-width: 1400px) {
      padding: 50px 20px;
    }
    @media screen and (max-width: 600px) {
      padding: 50px 16px;
    }
    .box-padding {
      padding: 16px;
    }
    &-border-bottom {
      border-bottom: 1px solid #3f1c1c;
    }
    &-header {
      text-align: center;
      h3 {
        font-size: 40px;
        margin-bottom: 20px;
        @media screen and (max-width: 600px) {
          font-size: 32px;
          margin-bottom: 16px;
        }
      }
      p {
        color: #e0e0e0;
        max-width: 876px;
        margin: 0 auto;
        line-height: 1.5;
      }
    }
    &-title {
      font-size: 20px;
      color: #faab48;
      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }
    &-subtitle {
      font-size: 16px;
      color: #faab48;
      font-family: "Poppins", sans-serif !important;
    }
    &-main {
      margin-top: 60px;
      display: flex;
      gap: 24px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }
    }
    &-left {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 250px;
      @media screen and (max-width: 1200px) {
        width: 500px;
        margin: 0 auto;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      &-item {
        background-color: #311912;
        border-radius: 12px;
        &:last-child {
          flex: 1;
        }
        &-list {
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:not(:first-child) {
              margin-top: 20px;
            }
          }
        }
      }
    }
    &-right {
      background-color: #311912;
      border-radius: 12px;
      flex: 1;
      &-row {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        padding: 16px;

        &-1 {
          grid-area: 1 / 1 / 2 / 2;
        }
        &-2 {
          grid-area: 1 / 2 / 2 / 4;
        }
        &-3 {
          grid-area: 1 / 4 / 2 / 6;
        }
        &-4 {
          grid-area: 1 / 6 / 2 / 8;
        }
        &-label {
          background-color: #432218;
          @media screen and (max-width: 1000px) {
            display: none;
          }
        }
        &-mb {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding: 16px 8px;
          gap: 16px;
          &-item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            &-label {
              color: #828282;
              font-size: 12px;
            }
          }
        }
        &-group {
          margin-top: 20px;
          &-header {
            padding: 4px 16px;
            background-color: #432218;
            border-radius: 8px 8px 0px 0px;
          }
        }
      }
      &-box {
        margin-top: 20px;
        &-header {
          padding: 4px 16px;
          border-radius: 8px 8px 0px 0px;
          background: #432218;
        }
        &-main {
          @media screen and (max-width: 1000px) {
            display: none;
          }
          &-mb {
            display: none;
            @media screen and (max-width: 1000px) {
              display: block;
            }
          }
          &-item {
            &:not(:last-child) {
              border-bottom: 1px solid #3f1c1c;
            }
          }
        }
      }
    }
    &-token {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.history {
  max-width: 1280px;
  margin: 0 auto;
  padding: 50px;
  @media screen and (max-width: 1400px) {
    padding: 50px 20px;
  }
  @media screen and (max-width: 600px) {
    padding: 50px 16px;
  }
  &-wrapper {
    background-color: #251015;
    padding: 100px 0;
    @media screen and (max-width: 1000px) {
      padding: 80px 0;
    }
  }
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      text-align: center;
      font-size: 40px;
      color: #faab48;
      margin-top: 40px;
      @media screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
  }
}

.histories {
  margin-top: 60px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }
  &-box {
    border-radius: 8px;
    border: 2px solid #643842;
    &-header {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #3f1c1c;
      &-clock {
        color: #faab48;
        font-size: 20px;
        font-family: "Coiny", cursive !important;
      }
      &-left,
      &-right {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }
    }
    &-main {
      padding: 16px;
      &-item {
        &:not(:first-child) {
          margin-top: 32px;
        }
        &-box {
          margin-top: 16px;
          display: flex;
          align-items: center;
          background-color: #331616;
          padding: 0 12px;
          height: 65px;
          border-radius: 8px;
          gap: 8px;
          &-small {
            // display: flex;
            margin-top: 16px;
            background-color: #331616;
            padding: 8px 12px;
            border-radius: 4px;
            @media screen and (max-width: 600px) {
              font-size: 14px;
            }
            &-col {
              display: flex;
              gap: 50px;
              &-grid {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                grid-template-rows: 1fr;
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                @media screen and (max-width: 600px) {
                  font-size: 14px;
                  grid-template-columns: repeat(17, 1fr);
                }
              }
            }
            &-row {
              display: flex;
              align-items: center;
              gap: 8px;

              &-grid {
                display: flex;
                align-items: center;
                gap: 8px;
                @media screen and (max-width: 600px) {
                  gap: 4px;
                }
                &-1 {
                  grid-area: 1 / 1 / 2 / 2;
                  @media screen and (max-width: 600px) {
                    grid-area: 1 / 1 / 2 / 3;
                  }
                }
                &-2 {
                  grid-area: 1 / 2 / 2 / 4;
                  @media screen and (max-width: 600px) {
                    grid-area: 1 / 3 / 2 / 8;
                  }
                }
                &-3 {
                  grid-area: 1 / 4 / 2 / 6;
                  @media screen and (max-width: 600px) {
                    grid-area: 1 / 8 / 2 / 13;
                  }
                }
                &-4 {
                  grid-area: 1 / 6 / 2 / 8;
                  @media screen and (max-width: 600px) {
                    grid-area: 1 / 13 / 2 / 18;
                  }
                }
              }
            }
          }
          &-left {
            flex-shrink: 0;
          }
          &-right {
            display: flex;
            flex-direction: column;
            gap: 6px;
            @media screen and (max-width: 600px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.text-gray {
  font-size: 14px;
  color: #bdbdbd;
  letter-spacing: 0.14px;
  word-break: break-word;
}

.text-14 {
  display: block;
  margin-top: 20px;
  font-size: 14px;
}

.text-pop-primary {
  font-weight: 500px;
  color: #faab48;
  letter-spacing: 0.16px;
}

.footer {
  padding: 100px;
  background-image: url("./assets/img/footer.svg");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 1400px) {
    padding: 50px;
  }
  @media screen and (max-width: 1000px) {
    padding: 50px 25px;
  }
}

.text-black {
  margin-top: 10px;
  text-align: center;
  color: #000;
  font-size: 20px;
  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
}
